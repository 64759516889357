import 'react-phone-number-input/style.css'
import dynamic, { DynamicOptions } from 'next/dynamic'
import { InternationalPhoneNumberInputProps } from '@/atoms/Input/IntlPhoneInput/InternationalPhoneNumberInput'
import { logger } from '@/utils/logging'

export const IntlPhoneInput = dynamic(
  import('./InternationalPhoneNumberInput')
    .then((mod) => mod.InternationalPhoneNumberInput)
    .catch((err) =>
      logger().error(`Failed to load the InternationalPhoneNumberInput!`, err),
    ) as DynamicOptions<InternationalPhoneNumberInputProps>,
  { ssr: false },
)
