import React from 'react'
import { FlexRow } from '@/atoms/FlexContainers'
import { Image } from '@/atoms/Image'
import { ParagraphSM, TitleXS, CaptionMD } from '@/atoms/Text'
import { TheatricalReleaseObject, CriticScore as Score, Critic, Maybe } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'
import { BaseTooltip } from './ToolTips'

const IMAGES = {
  VERIFIED_HOT: 'v1732750381/a209eb3767a02b08bb0045a8118a4ca4.png',
  POPCORN: 'v1688691818/angel-studios/misc-icons/popcorn.svg',
  TOMATO: 'v1688691818/angel-studios/misc-icons/rotten-tomatoe.svg',
} as const

interface CriticScoreProps {
  theatricalRelease: TheatricalReleaseObject
}

const ScoreDisplay = ({
  value,
  imageSrc,
  hideSeperator = false,
}: {
  value: number
  imageSrc: string
  hideSeperator?: boolean
}) => (
  <div className="flex items-center gap-2">
    {!hideSeperator && <div className="h-1 w-1 rounded-full bg-core-gray-400" />}
    <FlexRow className={`gap-1 ${hideSeperator ? 'pr-2' : ''}`}>
      <Image className="rounded-xl shadow-xl" alt="" src={imageSrc} width={16} height={16} />
      <CaptionMD className="uppercase">{value}%</CaptionMD>
    </FlexRow>
  </div>
)

const TooltipContent = () => {
  const { t } = useTranslate('tickets')

  return (
    <>
      <TitleXS color="core-gray-950" weight="bold">
        {t('whatIsVerifiedHotTitle', 'Verified Hot Score')}
      </TitleXS>
      <ParagraphSM className="mt-2 text-[0.688rem] leading-[16px]" color="core-gray-700">
        {t(
          'whatIsVerifiedHot',
          'The Verified Hot status and badge is an elevated designation honoring the highest verified reviewed theatrical films by fans on Rotten Tomatoes. Verified Hot is composed of Verified Ratings and Reviews from the Popcornmeter score.',
        )}
      </ParagraphSM>
    </>
  )
}

const findScore = (critic: Maybe<Critic> | undefined | null, scoreName: Score['name']) =>
  critic?.scores?.find((score: Maybe<Score>) => score?.name === scoreName)

const isScoreAboveThreshold = (score: Score | undefined | null, threshold: number) =>
  score?.value ? score.value >= threshold : false

export const CriticScore = ({ theatricalRelease }: CriticScoreProps) => {
  const rottenTomatoesCritic = theatricalRelease?.critics?.find((critic) => critic?.company === 'Rotten Tomatoes')

  const audienceScore = findScore(rottenTomatoesCritic, 'Audience')
  const tomatoMeterScore = findScore(rottenTomatoesCritic, 'Tomatometer')

  const showAudience = isScoreAboveThreshold(audienceScore, 70)
  const showTomatometer = isScoreAboveThreshold(tomatoMeterScore, 70)
  const isVerifiedHot = isScoreAboveThreshold(audienceScore, 90)

  if (!showAudience && !showTomatometer) return null

  const audienceDisplay = (
    <ScoreDisplay
      value={audienceScore?.value as number}
      imageSrc={isVerifiedHot ? IMAGES.VERIFIED_HOT : IMAGES.POPCORN}
    />
  )

  return (
    <div className="flex items-center">
      {showTomatometer && (
        <ScoreDisplay
          value={tomatoMeterScore?.value as number}
          imageSrc={IMAGES.TOMATO}
          hideSeperator={Boolean(showAudience && showTomatometer)}
        />
      )}
      {showAudience &&
        (isVerifiedHot ? (
          <BaseTooltip
            buttonClassName="!p-0 bg-black/10 p-1 bg-[#FFF] bg-opacity-20 border-0 !focus-visible:outline-0 focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0"
            panelClassName="!z-[9999] !outline-none !top-[26px]"
            trigger={audienceDisplay}
            content={<TooltipContent />}
            removeBorder={false}
            panelPosition="CENTER"
          />
        ) : (
          audienceDisplay
        ))}
    </div>
  )
}
