import dynamic, { DynamicOptions } from 'next/dynamic'
import { TicketNavProps } from '@/layouts/TicketLayout/components/TicketNavBar/TicketNav'
import { logger } from '@/utils/logging'

export const TicketNavBar = dynamic(
  import('./TicketNav')
    .then((mod) => mod.default)
    .catch((err) => logger().error(`Failed to load the Ticket Nav Bar!`, err)) as DynamicOptions<TicketNavProps>,
  { ssr: false },
)
