import 'react-phone-number-input/style.css'
import dynamic, { DynamicOptions } from 'next/dynamic'
import { AngelVerificationInputComponentProps } from '@/atoms/Input/VerificationInput/AngelVerificationInputComponent'
import { logger } from '@/utils/logging'

export const AngelVerificationInput = dynamic(
  import('./AngelVerificationInputComponent')
    .then((mod) => mod.AngelVerificationInputComponent)
    .catch((err) =>
      logger().error(`Failed to load the AngelVerificationInputComponent!`, err),
    ) as DynamicOptions<AngelVerificationInputComponentProps>,
  { ssr: false },
)
