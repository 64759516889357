import React from 'react'
import { CircleCloseIcon } from '@/atoms/Icons/CircleCloseIcon'
import { EmbeddedVideo } from '@/molecules/EmbeddedVideo'
import { Modal } from '@/molecules/Modal'
import { TheatricalTrailerExperimentType } from '@/services/CmsService/TheatricalTrailerExperiment'

interface TrailerModalProps {
  open: boolean
  onClose: () => void
  trailerUrl: string
  trailerExperiment?: TheatricalTrailerExperimentType
}
export const TrailerModal = ({ open, onClose, trailerUrl }: TrailerModalProps) => {
  return (
    <Modal
      backgroundOverlayClassName="!bg-black/90"
      backgroundScreenColor="black"
      isOpen={open}
      onClose={onClose}
      panelClasses="!bg-transparent min-w-[90%] relative !overflow-visible text-center !p-3"
      transition="appear"
      className="z-[9999999]"
    >
      <div>
        <div
          className="absolute right-2 top-2 z-[10000000] h-8 w-8 cursor-pointer rounded-full bg-transparent transition-all duration-300 ease-in-out hover:opacity-80 md:right-4 md:top-6"
          onClick={onClose}
        >
          <CircleCloseIcon color="core-gray-100" size={32} className="cursor-pointer" />
        </div>
        <div>
          <EmbeddedVideo iframeStyle={{ borderRadius: 10 }} url={`${trailerUrl}?autoplay=1`} />
        </div>
      </div>
    </Modal>
  )
}
