import React, { useState } from 'react'
import classNames from 'classnames'
import { Image } from '@/atoms/Image'
import { YoutubeVideoModal } from '@/molecules/YoutubeVideoModal'
import { useGiveawaySchedule } from '@/views/PreSales/PreSalesGiveawayView/hooks/useGiveawaySchedule'

interface GiveawayMultiplierBannerProps {
  theatricalSlug: string
  theme?: string
  mobileclassName?: string
}

export const GiveawayMultiplierBanner: React.FC<GiveawayMultiplierBannerProps> = ({
  theatricalSlug,
  theme = '',
  mobileclassName = '',
}) => {
  const { normal } = useGiveawaySchedule(theatricalSlug)
  const [showModal, setShowModal] = useState(false)
  const multiplier = normal?.multiplier ?? 1

  const validMultiplier = Math.min(Math.max(Math.floor(multiplier), 1), 9)

  const handleClick = () => {
    setShowModal(true)
  }

  return (
    <div className="relative @container">
      <div className="relative hidden cursor-pointer lg:block" onClick={handleClick}>
        <Image
          className={classNames('h-full w-full', theme)}
          src={`/v1737151137/dream%20vacation%20banners/dream-vacation-banner-${validMultiplier}x-desk-01.webp`}
          alt={''}
          width={2000}
          height={334}
        />
      </div>
      <div className="relative hidden cursor-pointer md:block lg:hidden" onClick={handleClick}>
        <Image
          className={classNames('h-full w-full', theme)}
          src={`/v1737151137/dream%20vacation%20banners/dream-vacation-banner-${validMultiplier}x-tablet-01.webp`}
          alt={''}
          width={1200}
          height={300}
        />
      </div>
      <div className={classNames('relative md:hidden', mobileclassName, theme)} onClick={handleClick}>
        <Image
          src={`/v1737151137/dream%20vacation%20banners/dream-vacation-banner-${validMultiplier}x-mobile-01.webp`}
          alt={''}
          className="h-full w-full"
          width={856}
          height={286}
        />
      </div>
      {theatricalSlug === 'brave-the-dark' && (
        <YoutubeVideoModal
          youTubeUrl={'https://www.youtube-nocookie.com/embed/knvKDPwWo3I'}
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          className="!z-[60]"
        />
      )}
    </div>
  )
}
