import { useCallback, useMemo, useRef, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import axios from 'axios'
import { useUser } from '@/services/UserService'
import { useLocale } from '@/utils/LocaleUtil'
import { UserLocation } from '@/utils/location/location'
import { logger } from '@/utils/logging'
import { useTranslate } from '@/utils/translate/translate-client'

interface searchResult {
  geometry: {
    coordinates: [number, number]
  }
  properties: {
    label: string
    id: string
  }
}

export interface AddressSearchResult extends Omit<searchResult, 'geometry'> {
  geometry: {
    coordinates: {
      latitude: number
      longitude: number
    }
  }
}

interface Props {
  countryCode: string
  limitExperiment?: boolean
}

const extractCoordinates = (geometry: searchResult['geometry']) => {
  return {
    coordinates: {
      latitude: geometry?.coordinates?.[1],
      longitude: geometry?.coordinates?.[0],
    },
  }
}

const formatSearchResults = (searchResults: searchResult[] | undefined) => {
  const result = searchResults?.[0]
  if (!result) return {}
  return { ...result, geometry: extractCoordinates(result.geometry) }
}
interface SearchParams {
  term: string
  lang: string
  abort: AbortController
  countryCode: string
  coordinates?: UserLocation['coordinates']
}

const searchForLocation = async ({
  term,
  abort,
  lang,
  coordinates,
  countryCode,
}: SearchParams): Promise<AddressSearchResult> => {
  const params = {
    text: term,
    ['api_key']: process.env.NEXT_PUBLIC_TICKET_SEARCH_KEY,
    ['focus.point.lat']: coordinates?.latitude,
    ['focus.point.lon']: coordinates?.longitude,
    ['boundary.country']: countryCode,
    layers: '-country,-region,-continent,-street,-intersection,-address,-neighbourhood,-borough',
    lang,
  }

  const { data } = await axios.get('https://api.geocode.earth/v1/search?', {
    params,
    signal: abort.signal,
  })

  return formatSearchResults(data?.features) as AddressSearchResult
}

export const useAddressSearch = ({ countryCode }: Props) => {
  const { t } = useTranslate('tickets')
  const { locale } = useLocale()
  const { userLocation } = useUser()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const abortRef = useRef(new AbortController())
  const searchCountryCode = useMemo(() => {
    if (countryCode === 'US' || countryCode === 'CA') return 'US,CA'
    return countryCode
  }, [countryCode])

  const search = useCallback(
    async (term: string): Promise<AddressSearchResult | null> => {
      try {
        if (!term) return null

        setLoading(true)
        setError(null)

        return await searchForLocation({
          term: term.replace(' ', '+'),
          lang: locale,
          coordinates: userLocation?.coordinates,
          abort: abortRef.current,
          countryCode: searchCountryCode,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (axios.isCancel(err)) return null
        logger().warn('Failed to do address search!', err)
        Bugsnag.notify(new Error(`Failed to do address search: ${err.message}`))
        setError(err?.message ?? t('somethingWentWrongWhileSearching', 'Something went wrong while searching'))
        return null
      } finally {
        setLoading(false)
      }
    },
    [locale, searchCountryCode, t, userLocation?.coordinates],
  )

  return useMemo(() => {
    return { search, loading, error }
  }, [error, loading, search])
}
