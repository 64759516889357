import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import {
  CaptionSM,
  EyebrowMD,
  EyebrowSM,
  FontWeight,
  HeadingLG,
  ParagraphLG,
  Text,
  TextProps,
  TitleSM,
} from '@/atoms/Text'
import { slugs } from '@/constants/slugs'
import { NamedColor } from '@/constants/types'
import { useGiveawayProjectContext } from '@/views/PreSales/PreSalesGiveawayView/components/GiveawayProjectProvider'

interface TextCompontent {
  color?: NamedColor
  weight?: FontWeight
  onClick?: () => void
  children: ReactNode
  className?: string
}

interface Props extends TextProps {
  className?: string
  children: ReactNode
}
export const GDisplay: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('display-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('display-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('display-homestead', className)}>
      {children}
    </Text>
  )
}

export const GTitleXXL: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('title-xxl-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('title-xxl-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('title-xxl-homestead', className)}>
      {children}
    </Text>
  )
}

export const GTitleXL: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('title-xl-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('title-xl-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('title-xl-homestead', className)}>
      {children}
    </Text>
  )
}

export const GTitleLG: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('title-lg-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('title-lg-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('title-lg-homestead', className)}>
      {children}
    </Text>
  )
}

export const GTitleMD: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('title-md-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('title-md-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('title-md-homestead', className)}>
      {children}
    </Text>
  )
}

export const GTitleSM: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('title-sm-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('title-sm-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('title-sm-homestead', className)}>
      {children}
    </Text>
  )
}

export const GTitleXS: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('title-xs-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('title-xs-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('title-xs-homestead', className)}>
      {children}
    </Text>
  )
}

export const GEyebrowXL: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('eyebrow-xl-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('eyebrow-xl-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('eyebrow-xl-homestead', className)}>
      {children}
    </Text>
  )
}

export const GEyebrowLG: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('eyebrow-lg-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('eyebrow-lg-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('eyebrow-lg-homestead', className)}>
      {children}
    </Text>
  )
}

export const GEyebrowMD: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('eyebrow-md-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('eyebrow-md-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('eyebrow-md-homestead', className)}>
      {children}
    </Text>
  )
}

export const GEyebrowSM: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('eyebrow-sm-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('eyebrow-sm-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('eyebrow-sm-homestead', className)}>
      {children}
    </Text>
  )
}

export const GParagraphLG: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('paragraph-lg-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('paragraph-lg-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('paragraph-lg-homestead', className)}>
      {children}
    </Text>
  )
}

export const GParagraphMD: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('paragraph-md-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('paragraph-md-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('paragraph-md-homestead', className)}>
      {children}
    </Text>
  )
}
export const GPromoTextSM: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('promo-text-sm-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('promo-text-sm-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('promo-text-sm-homestead', className)}>
      {children}
    </Text>
  )
}

export const GPromoTextMD: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('promo-text-md-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('promo-text-md-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('promo-text-md-homestead', className)}>
      {children}
    </Text>
  )
}

export const GParagraphSM: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('paragraph-sm-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('paragraph-sm-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('paragraph-sm-homestead', className)}>
      {children}
    </Text>
  )
}

export const GCaptionMD: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('caption-md-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('caption-md-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('caption-md-homestead', className)}>
      {children}
    </Text>
  )
}

export const GCaptionSM: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('caption-sm-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('caption-sm-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('caption-sm-homestead', className)}>
      {children}
    </Text>
  )
}

export const GFooterMD: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('footer-md-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('footer-md-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('footer-md-homestead', className)}>
      {children}
    </Text>
  )
}

export const GFooterSM: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('footer-sm-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('footer-sm-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('footer-sm-homestead', className)}>
      {children}
    </Text>
  )
}

export const GButtonMD: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('btn-md-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('btn-md-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('btn-md-homestead', className)}>
      {children}
    </Text>
  )
}

export const GButtonSM: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('btn-sm-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('btn-sm-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('btn-sm-homestead', className)}>
      {children}
    </Text>
  )
}

export const GButtonXS: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('btn-xs-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('btn-xs-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('btn-xs-homestead', className)}>
      {children}
    </Text>
  )
}

export const GTicketValue: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('ticket-value-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('ticket-value-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('ticket-value-homestead', className)}>
      {children}
    </Text>
  )
}

export const GStepValueXL: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('step-value-xl-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('step-value-xl-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('step-value-xl-homestead', className)}>
      {children}
    </Text>
  )
}

export const GStepValueLG: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('step-value-lg-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('step-value-lg-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('step-value-lg-homestead', className)}>
      {children}
    </Text>
  )
}

export const GStepValueMD: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('step-value-md-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('step-value-md-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('step-value-md-homestead', className)}>
      {children}
    </Text>
  )
}

export const GTabText: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayProjectContext()

  if (theatricalSlug === slugs.braveTheDark) {
    return (
      <Text {...textProps} className={classNames('tab-text-brave-the-dark', className)}>
        {children}
      </Text>
    )
  }

  if (theatricalSlug === slugs.ruleBreakers) {
    return (
      <Text {...textProps} className={classNames('tab-text-rule-breakers', className)}>
        {children}
      </Text>
    )
  }

  return (
    <Text {...textProps} className={classNames('tab-text-homestead', className)}>
      {children}
    </Text>
  )
}

interface ResponsiveTextProps extends TextProps {
  mobileClassName?: string
  desktopClassName?: string
  className?: string
  children: ReactNode
  MobileTextComponent: React.ComponentType<Props>
  DesktopTextComponent: React.ComponentType<Props>
}

export const GResponsiveText: FC<ResponsiveTextProps> = ({
  children,
  MobileTextComponent,
  DesktopTextComponent,
  mobileClassName,
  desktopClassName,
  className,
  ...textProps
}) => {
  return (
    <span>
      <MobileTextComponent className={classNames('md:hidden', className, mobileClassName)} {...textProps}>
        {children}
      </MobileTextComponent>

      <DesktopTextComponent className={classNames('hidden md:block', className, desktopClassName)} {...textProps}>
        {children}
      </DesktopTextComponent>
    </span>
  )
}

export const HomesteadTitleXL: FC<TextCompontent> = ({ className, children }) => (
  <h1
    className={classNames(
      'text-[40px] font-extrabold tracking-[1.2px] leading-none uppercase !font-[dharma-gothic-e]',
      className,
    )}
  >
    {children}
  </h1>
)

export const HomesteadTitleLG: FC<TextCompontent> = ({ className, children, color, weight }) => (
  <HeadingLG
    className={classNames(
      'text-[32px] leading-none font-extrabold tracking-[0.9px] uppercase !font-[dharma-gothic-e]',
      className,
    )}
    color={color ? color : 'homestead-primary-blue'}
    weight={weight}
  >
    {children}
  </HeadingLG>
)

export const HomesteadTitleMD: FC<TextCompontent> = ({ className, children, color, weight }) => (
  <HeadingLG
    className={classNames(
      'text-[28px] leading-none font-extrabold tracking-[1.4px] uppercase !font-[dharma-gothic-e]',
      className,
    )}
    color={color ? color : 'homestead-primary-blue'}
    weight={weight}
  >
    {children}
  </HeadingLG>
)

export const HomesteadTitleSM: FC<TextCompontent> = ({ className, children, color, weight }) => (
  <TitleSM
    className={classNames('text-[21px] !font-[bio-sans] leading-[130%] font-[600]', className)}
    color={color ? color : 'homestead-primary-blue'}
    weight={weight}
  >
    {children}
  </TitleSM>
)

export const HomesteadTitleXS: FC<TextCompontent> = ({ className, children, color, weight }) => (
  <TitleSM
    className={classNames('text-lg !font-[bio-sans] leading-[130%] font-semibold', className)}
    color={color ? color : 'homestead-primary-blue'}
    weight={weight}
  >
    {children}
  </TitleSM>
)

export const HomesteadEyebrowMD: FC<TextCompontent> = ({ className, children, color, weight }) => (
  <EyebrowMD
    className={classNames('text-[14px] font-[500] !font-[bio-sans] uppercase tracking-[0.42px]', className)}
    color={color ? color : 'homestead-primary-blue'}
    weight={weight}
  >
    {children}
  </EyebrowMD>
)

export const HomesteadEyebrowSM: FC<TextCompontent> = ({ className, children, color, weight }) => (
  <EyebrowSM
    className={classNames('text-[12px] !font-[bio-sans] uppercase tracking-[0.36px]', className)}
    color={color ? color : 'homestead-primary-blue'}
    weight={weight}
  >
    {children}
  </EyebrowSM>
)

export const HomesteadParagraphMD: FC<TextCompontent> = ({ className, children, color, weight }) => (
  <ParagraphLG
    className={classNames('text-[18px] !font-[bio-sans] leading-[150%]', className)}
    color={color ? color : 'homestead-blue'}
    weight={weight}
  >
    {children}
  </ParagraphLG>
)

export const HomesteadParagraphSM: FC<TextCompontent> = ({ className, children, color, weight }) => (
  <ParagraphLG
    className={classNames('text-[16px] !font-[bio-sans] leading-[150%]', className)}
    color={color ? color : 'homestead-blue'}
    weight={weight}
  >
    {children}
  </ParagraphLG>
)

export const HomesteadCaptionSM: FC<TextCompontent> = ({ className, children, color, weight }) => (
  <CaptionSM
    className={classNames('text-[14px] leading-[150%] !font-[bio-sans] font-normal', className)}
    color={color}
    weight={weight}
  >
    {children}
  </CaptionSM>
)

export const HomesteadButtonText: FC<{ className?: string; children: ReactNode }> = ({ className, children }) => (
  <Text className={classNames('!font-[bio-sans] text-[16px] font-semibold uppercase leading-[120%]', className)}>
    {children}
  </Text>
)
