import dynamic, { DynamicOptions } from 'next/dynamic'
import { logger } from '@/utils/logging'
import { HideEmailSignInExperimentComponentProps } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/HideEmailSignInEpxeriment/HideEmailSignInExperiment'

export const HideEmailSignInExperiment = dynamic(
  import('./HideEmailSignInExperiment')
    .then((mod) => mod.HideEmailSignInExperimentComponent)
    .catch((err) =>
      logger().error(`Failed to load the GoogleSearchExperiment!`, err),
    ) as DynamicOptions<HideEmailSignInExperimentComponentProps>,
  { ssr: false },
)
