import React, { FC, useMemo } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { TicketPromoBanner } from 'src/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner'
import { TitleLG } from '@/atoms/Text'
import { slugs } from '@/constants/slugs'
import { GoalLineV3 } from '@/molecules/GoalLine/GoalLineV3'
import { TheatricalPromoType, TicketPromoTypes } from '@/services/Theatrical/contentfulQueries'
import { ReservationObject, TheatricalReleaseObject } from '@/types/codegen-federation'
import { useGiveawaySchedule } from '@/views/PreSales/PreSalesGiveawayView/hooks/useGiveawaySchedule'
import { DiscountType } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import { TicketsInfoIconTooltip } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsInfoIconTooltip'
import { BraveTheDarkDiscountBanner } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner/BraveTheDarkDiscountBanner'
import { BraveTheDarkTeacherBanner } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner/BraveTheDarkTeacherBanner'
import NoFeesBanner from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/NoFeesBanner'
import { isAngelIntegratedCountry } from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'
import useTheatricalGoalService from '@/views/TicketCheckoutViews/hooks/useTheatricalGoalService'
import { BraveTheDarkBOGO } from './banners/DiscountBanner/BraveTheDarkBOGO'
import { BraveTheDarkBraveTeacher } from './banners/DiscountBanner/BraveTheDarkBraveTeacher'
import { GiveawayMultiplierBanner } from './banners/GiveawayBanners/GiveawayMultiplierBanner'

interface TicketCarouselProps {
  discount?: DiscountType | null
  scrollToTop?: () => void
  theatricalSlug: string
  theatricalRelease: TheatricalReleaseObject
  className?: string
  reservation?: ReservationObject
  promo?: TheatricalPromoType
}
const MobileTicketCtaCarousel: FC<TicketCarouselProps> = ({
  className,
  discount,
  reservation,
  theatricalRelease,
  theatricalSlug,
  promo,
}) => {
  const { query } = useRouter()

  const countryCode = theatricalRelease?.region?.countryCode ?? 'US'
  const releaseDate = theatricalRelease?.region?.releaseDate ?? new Date()
  const currentPromo = promo
  const { goalLineInfo } = useTheatricalGoalService({
    theatricalSlug,
    releaseDate: theatricalRelease?.region?.releaseDate,
  })
  const { isActive } = useGiveawaySchedule(theatricalRelease?.theatricalSlug)

  const shouldShowPromoBanner =
    currentPromo?.type === TicketPromoTypes.EarlyBird ||
    !!discount ||
    currentPromo?.type === TicketPromoTypes.NoFees ||
    (query?.promo === 'bogo' && theatricalSlug === slugs.bonhoeffer)

  const validPromos = ['brave20', 'iamateacher', 'braveteacher', 'teacherbogo']
  const getQueryParam = (param: string | string[] | undefined): string =>
    typeof param === 'string' ? param.toLowerCase() : ''

  const currentUrlPromo = getQueryParam(query?.promo) || getQueryParam(query?.discounts)

  const promoBanners = {
    brave20: <BraveTheDarkDiscountBanner />,
    iamateacher: <BraveTheDarkTeacherBanner />,
    teacherbogo: <BraveTheDarkBOGO />,
    braveteacher: <BraveTheDarkBraveTeacher />,
  } as const

  const NoFeesSlide = useMemo(
    () => (
      <SplideSlide key="brave-the-dark-fees">
        <NoFeesBanner
          theatricalSlug={theatricalRelease?.theatricalSlug}
          className={'overflow-hidden !pr-0'}
          code="NOFEES"
        />
      </SplideSlide>
    ),
    [theatricalRelease?.theatricalSlug],
  )

  const GiveawaySlide = useMemo(
    () => (
      <SplideSlide key="brave-the-dark">
        <GiveawayMultiplierBanner theatricalSlug={theatricalSlug} />
      </SplideSlide>
    ),
    [theatricalSlug],
  )

  const getSlides = () => {
    const slides = [
      <SplideSlide key="goalline" className="relative w-full">
        <div className="flex h-full items-center bg-core-gray-950 px-6 py-4">
          <GoalLineV3
            goalLineInfo={goalLineInfo}
            shouldUseIncrementer
            releaseDate={releaseDate}
            theatricalSlug={theatricalSlug}
            countryCode={countryCode}
            isRounded={true}
          />
        </div>
      </SplideSlide>,
    ]

    if (currentUrlPromo && promoBanners[currentUrlPromo as keyof typeof promoBanners]) {
      slides.push(
        <SplideSlide key="promo" className="relative w-full">
          {promoBanners[currentUrlPromo as keyof typeof promoBanners]}
        </SplideSlide>,
      )
    }
    if (shouldShowPromoBanner && !!reservation?.id && isAngelIntegratedCountry(countryCode)) {
      slides.push(
        <SplideSlide key="ticket-promo">
          <TicketPromoBanner theatricalSlug={theatricalSlug} discount={discount as DiscountType} promo={promo} />
        </SplideSlide>,
      )
    }
    if (theatricalSlug === 'rule-breakers') {
      slides.push(
        <SplideSlide key="rule-breakers">
          <GiveawayMultiplierBanner theatricalSlug={theatricalSlug} />
        </SplideSlide>,
      )
    }
    if (theatricalSlug === 'brave-the-dark' && !validPromos.includes(currentUrlPromo)) {
      slides.push(isActive ? GiveawaySlide : NoFeesSlide)
    }

    return slides
  }

  if (isAngelIntegratedCountry(theatricalRelease?.region?.countryCode ?? ''))
    return (
      <Splide
        className={classNames('tickets-carousel z-[52]', className)}
        hasTrack={true}
        options={{
          arrows: false,
          autoplay: true,
          speed: 1000,
          type: 'loop',
          pagination: true,
          paginationDirection: 'ltr',
          interval: 6000,
          perMove: 1,
          perPage: 1,
          classes: { pagination: 'splide__pagination tickets-page !flex' },
        }}
      >
        {getSlides()}
      </Splide>
    )

  return (
    <div className={classNames('h-full bg-core-gray-950 px-6 py-4', className)}>
      <TitleLG weight="bold" className={classNames('justify-between text-gray-100 hidden md:flex')}>
        {goalLineInfo?.goalLineTitle}
        <TicketsInfoIconTooltip shouldUseNewAfterDeathGoal={theatricalSlug === slugs.afterDeath} />
      </TitleLG>
      <div className="md:mt-3 lg:mb-3">
        <GoalLineV3
          goalLineInfo={goalLineInfo}
          shouldUseIncrementer
          theatricalSlug={theatricalSlug}
          countryCode={countryCode}
          releaseDate={releaseDate}
          isRounded={true}
        />
      </div>
    </div>
  )
}

export default MobileTicketCtaCarousel
