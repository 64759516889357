import React, { useEffect, useState } from 'react'
import { useTranslate } from '@/utils/translate/translate-client'
import { HomesteadEyebrowMD } from '@/views/PreSales/PreSalesGiveawayView/components/Typography'

const getTimeRemaining = (endTime: string | Date) => {
  if (typeof endTime === 'string') {
    const utcDate = new Date(endTime)

    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/Denver',
      timeZoneName: 'short',
    })
    const parts = formatter.formatToParts(utcDate)
    const timeZoneName = parts.find((part) => part.type === 'timeZoneName')?.value

    const mountainTimeOffset = timeZoneName === 'MDT' ? 6 * 60 * 60 * 1000 : 7 * 60 * 60 * 1000
    endTime = new Date(utcDate.getTime() + mountainTimeOffset)
  }

  const total = endTime.getTime() - new Date().getTime()
  const seconds = Math.floor((total / 1000) % 60)
  const minutes = Math.floor((total / 1000 / 60) % 60)
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
  const days = Math.floor(total / (1000 * 60 * 60 * 24))

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  }
}

function getNextSundayMidnightMtn() {
  // Create date in Mountain Time
  const mtnDate = new Date().toLocaleString('en-US', { timeZone: 'America/Denver' })
  const now = new Date(mtnDate)
  const nextSunday = new Date(mtnDate)

  // Get days until next Sunday (0-6, where 0 is Sunday)
  const daysUntilSunday = (7 - nextSunday.getDay()) % 7

  // If it's already Sunday, we want this Sunday at midnight
  if (daysUntilSunday === 0) {
    nextSunday.setHours(23, 59, 59, 999) // Set to end of current Sunday
  } else {
    // Set to next Sunday
    nextSunday.setDate(now.getDate() + daysUntilSunday)
    nextSunday.setHours(23, 59, 59, 999)
  }

  return nextSunday
}

export interface WeeklyCountdownTimerProps {
  onCountdownEnd?: () => void
}

export const CountdownTimer: React.FC<WeeklyCountdownTimerProps> = ({ onCountdownEnd }) => {
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining(getNextSundayMidnightMtn()))
  const { t } = useTranslate('tickets')
  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeRemaining(getTimeRemaining(getNextSundayMidnightMtn()))
    }, 1000)

    return () => clearInterval(timerId)
  }, [])

  useEffect(() => {
    if (timeRemaining.total === 0 && onCountdownEnd) {
      onCountdownEnd()
    }
  }, [onCountdownEnd, timeRemaining.total])

  if (timeRemaining.total <= 0) {
    return null
  }

  const timer = `${timeRemaining.days}D ${timeRemaining.hours}H ${timeRemaining.minutes}M ${timeRemaining.seconds}S`

  return (
    <HomesteadEyebrowMD className="!font-apotek !text-[16px] !font-semibold !leading-[16px] !tracking-[0.75px]">
      {t('endingInTimerModal', 'Ending in {{timer}}', { timer })}
    </HomesteadEyebrowMD>
  )
}
