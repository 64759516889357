import React, { FC } from 'react'
import classNames from 'classnames'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { PlayIcon } from '@/atoms/Icons/PlayIcon'
import { Image } from '@/atoms/Image'
import { cloudinaryLoader } from '@/atoms/Image/Image'
import { AsH2, TitleSM } from '@/atoms/Text'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import TicketMovieDetails from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketMovieDetails'
import ExpandableDescription from './ExpandableDescription'

interface Props {
  theatricalRelease: TheatricalReleaseObject
  onTrailerClick: () => void
  duration?: string
  className?: string
  projectGuildScore: {
    guildScore: number | undefined
  }
}
const TrailerSection: FC<Props> = ({ theatricalRelease, onTrailerClick, className, duration, projectGuildScore }) => {
  const backgroundImageUrl = theatricalRelease?.verticalPoster
    ? cloudinaryLoader({
        src: '/' + theatricalRelease.verticalPoster,
        width: 400,
      })
    : undefined

  return (
    <div className={classNames(className, 'relative p-4 text-white')}>
      {/* Separate background container */}
      <div
        className={classNames(
          'absolute inset-0 max-w-[975px] mx-auto left-0 right-0',
          'overflow-hidden', // Overflow only on background container
          'md:hidden', // Hide on md breakpoint
        )}
      >
        <div
          className={classNames(
            'absolute inset-0',
            'bg-cover bg-center bg-no-repeat',
            'scale-150 blur-xl',
            'before:absolute before:inset-0 before:content-[""] before:bg-black/40',
          )}
          style={backgroundImageUrl ? { backgroundImage: `url("${backgroundImageUrl}")` } : undefined}
        />
      </div>

      <div className="absolute inset-0 bg-black/40 md:hidden" />
      <FlexRow className="z-10 gap-4">
        <button onClick={onTrailerClick} className="relative shrink-0 cursor-pointer">
          {theatricalRelease?.verticalPoster && (
            <Image
              className="rounded-xl shadow-xl"
              alt=""
              src={theatricalRelease?.verticalPoster}
              width={73}
              height={106}
            />
          )}
          <div className="absolute -right-2 -top-2 flex h-8 w-8 items-center justify-center rounded-full bg-white/20 backdrop-blur-[24px]">
            <PlayIcon color="white" />
          </div>
        </button>
        <FlexColumn className="z-10 w-full gap-1">
          <TitleSM as={AsH2} weight="bold">
            {theatricalRelease?.title}
          </TitleSM>
          <TicketMovieDetails
            duration={duration as string}
            theatricalRelease={theatricalRelease}
            projectGuildScore={projectGuildScore}
          />
          <ExpandableDescription className="md:hidden" text={theatricalRelease?.longDescription} />
        </FlexColumn>
      </FlexRow>
    </div>
  )
}

export default TrailerSection
