import React, { useState, FC } from 'react'
import { CaptionMD } from '@/atoms/Text'

interface ExpandableDescriptionProps {
  text?: string | null
  maxLength?: number
  className?: string
}

const ExpandableDescription: FC<ExpandableDescriptionProps> = ({ text, maxLength = 100, className }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  if (!text || text.length <= maxLength) {
    return (
      <div className={className}>
        <div className="text-core-gray-300">{text}</div>
      </div>
    )
  }

  const truncatedText = isExpanded ? text : `${text.slice(0, maxLength)}...`

  return (
    <div className={className}>
      <CaptionMD color="core-gray-300" className="pb-2">
        {!isExpanded ? (
          <>
            {truncatedText}{' '}
            <button
              onClick={() => setIsExpanded(true)}
              className="inline font-normal text-gray-400 underline hover:text-gray-300"
            >
              read more
            </button>
          </>
        ) : (
          <>
            {text}{' '}
            <button
              onClick={() => setIsExpanded(false)}
              className="inline font-normal text-gray-400 underline hover:text-gray-300"
            >
              read less
            </button>
          </>
        )}
      </CaptionMD>
    </div>
  )
}
export default ExpandableDescription
