import React, { useCallback } from 'react'
import classNames from 'classnames'
import { Image } from '@/atoms/Image'
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard'
import { useTranslate } from '@/utils/translate/translate-client'

interface PromoBannerProps {
  mobileclassName?: string
}

export const BraveTheDarkBOGO: React.FC<PromoBannerProps> = ({ mobileclassName = '' }) => {
  const { t } = useTranslate('tickets')
  const [copyToClipboard] = useCopyToClipboard({
    customToastMessage: t('discountBannerImageCodeLinkCopied', 'Discount Code Copied!'),
  })
  const handleImageClick = useCallback(() => {
    copyToClipboard('TEACHERBOGO')
  }, [copyToClipboard])
  return (
    <>
      <div className={classNames('md:hidden', mobileclassName)} onClick={handleImageClick}>
        <Image
          src={`/v1737232823/brave-the-dark/bogo-teacher-tix-banner-mobile-01.jpg`}
          className="h-full w-full"
          alt=""
          width={856}
          height={286}
        />
      </div>
      <div className="hidden cursor-pointer md:block lg:hidden" onClick={handleImageClick}>
        <Image
          className="h-full w-full"
          src={`/v1737232823/brave-the-dark/bogo-teacher-tix-banner-tablet-01.jpg`}
          alt=""
          width={1200}
          height={300}
        />
      </div>
      <div className="hidden cursor-pointer lg:block" onClick={handleImageClick}>
        <Image
          className="h-full w-full"
          src={`/v1737232823/brave-the-dark/bogo-teacher-tix-banner-desk-01.jpg`}
          alt=""
          width={2000}
          height={334}
        />
      </div>
    </>
  )
}
