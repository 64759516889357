import React, { useCallback, useEffect, useRef } from 'react'
import { Button } from '@/atoms/Button'
import { FilledTicketIcon } from '@/atoms/Icons/FilledTicketIcon'
import { EmbeddedVideo } from '@/molecules/EmbeddedVideo'
import { useTranslate } from '@/utils/translate/translate-client'

interface ScenePlayerProps {
  sceneId: string
  isFullScreen: boolean
  handleCloseFullScreen: () => void
  handleGetShowtimesClick: () => void
}
const ScenePlayer = ({ sceneId, isFullScreen, handleCloseFullScreen, handleGetShowtimesClick }: ScenePlayerProps) => {
  const { t } = useTranslate('tickets')

  const sceneRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (event && sceneRef.current && !sceneRef.current.contains(event.target as Node)) {
        handleCloseFullScreen()
      }
    },
    [handleCloseFullScreen],
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <div onClick={(e) => e.stopPropagation()} ref={sceneRef} className="w-full max-w-[900px]">
      <div className="aspect-h-9 aspect-w-16 flex w-full items-center justify-between rounded-xl bg-core-gray-950">
        <div className="lg:rounded-xl">
          <span className="aspect-h-9 aspect-w-16 block">
            <EmbeddedVideo
              url={`https://www.youtube-nocookie.com/embed/${sceneId}`}
              className="h-full w-full md:rounded-xl"
            />
          </span>
        </div>
      </div>
      {isFullScreen && (
        <Button onClick={handleGetShowtimesClick} className="mx-auto mt-6 flex items-center gap-1" variant="white">
          <FilledTicketIcon color="core-gray-950" />
          {t('getShowtimes', 'Get Showtimes')}
        </Button>
      )}
    </div>
  )
}

export default ScenePlayer
