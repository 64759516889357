import React, { FC } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { TheatricalPromoType } from '@/services/Theatrical/contentfulQueries'
import { ReservationObject, TheatricalReleaseObject } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'
import { useGiveawaySchedule } from '@/views/PreSales/PreSalesGiveawayView/hooks/useGiveawaySchedule'
import { DiscountType, useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import ChangeReservationBanner from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/ChangeReservationBanner'
import { TicketPromoBanner } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner'
import { BraveTheDarkBOGO } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner/BraveTheDarkBOGO'
import { BraveTheDarkBraveTeacher } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner/BraveTheDarkBraveTeacher'
import { BraveTheDarkDiscountBanner } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner/BraveTheDarkDiscountBanner'
import { BraveTheDarkTeacherBanner } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner/BraveTheDarkTeacherBanner'
import { GiveawayMultiplierBanner } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/GiveawayBanners/GiveawayMultiplierBanner'
import { GuildTicketsBanner } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/GuildTicketsBanner'
import NoFeesBanner from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/NoFeesBanner'
import { isAngelIntegratedCountry } from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'
import { useGuildTickets } from '@/views/TicketCheckoutViews/hooks/useGuildTickets'

interface BannerSectionProps {
  reservation?: ReservationObject
  isEditReservationFlow: boolean
  theatricalRelease: TheatricalReleaseObject
  promo?: TheatricalPromoType
  calendarRef?: React.RefObject<HTMLDivElement>
  promoPlacementExperiment?: boolean
}

const TicketBannerSection: FC<BannerSectionProps> = ({
  promo,
  reservation,
  isEditReservationFlow,
  theatricalRelease,
  calendarRef,
  promoPlacementExperiment,
}) => {
  const PROMO_BANNERS = {
    brave20: <BraveTheDarkDiscountBanner mobileclassName="!hidden" />,
    iamateacher: <BraveTheDarkTeacherBanner mobileclassName="!hidden" />,
    teacherbogo: <BraveTheDarkBOGO mobileclassName="!hidden" />,
    braveteacher: <BraveTheDarkBraveTeacher mobileclassName="!hidden" />,
  }
  const { t } = useTranslate('tickets')
  const { discount } = useTicketsContext()
  const { query } = useRouter()
  const { areGuildTicketsAvailable, discountCodes } = useGuildTickets(theatricalRelease?.theatricalSlug)
  const { isActive, isReady } = useGiveawaySchedule(theatricalRelease?.theatricalSlug)
  const countryCode = theatricalRelease?.region?.countryCode
  const validPromos = ['brave20', 'iamateacher', 'braveteacher', 'teacherbogo']
  const getQueryParam = (param: string | string[] | undefined): string =>
    typeof param === 'string' ? param.toLowerCase() : ''

  const currentUrlPromo = getQueryParam(query?.promo) || getQueryParam(query?.discounts)
  const isBravePromo = validPromos.includes(currentUrlPromo)

  return (
    <>
      {reservation?.id && !isEditReservationFlow && isAngelIntegratedCountry(countryCode) && (
        <TicketPromoBanner
          theatricalSlug={theatricalRelease?.theatricalSlug}
          className="hidden w-full md:flex"
          promo={promo}
          discount={discount as DiscountType}
          calendarRef={calendarRef}
        />
      )}
      {reservation?.priorReservation?.id &&
        reservation?.isChangeReservation &&
        isAngelIntegratedCountry(countryCode) && (
          <ChangeReservationBanner
            description={t('pleaseSelectANewShowtimeBelow', 'Please select a new showtime below.')}
            priorReservation={reservation?.priorReservation}
            step={1}
          />
        )}
      {areGuildTicketsAvailable && !promoPlacementExperiment && (
        <GuildTicketsBanner
          discountCodes={discountCodes}
          projectName={theatricalRelease?.title ?? ''}
          theatricalSlug={theatricalRelease?.theatricalSlug}
          className="overflow-hidden lg:hidden"
        />
      )}
      {theatricalRelease?.theatricalSlug === 'rule-breakers' && (
        <GiveawayMultiplierBanner theatricalSlug={theatricalRelease?.theatricalSlug} mobileclassName="!hidden" />
      )}

      {theatricalRelease?.theatricalSlug === 'brave-the-dark' && !isBravePromo && isActive && isReady && (
        <GiveawayMultiplierBanner
          theatricalSlug={theatricalRelease?.theatricalSlug}
          mobileclassName={classNames(
            ' h-full w-full overflow-hidden !pt-0',
            promoPlacementExperiment ? '' : '!hidden',
          )}
          theme={promoPlacementExperiment ? 'rounded-xl my-4' : ''}
        />
      )}
      {theatricalRelease?.theatricalSlug === 'brave-the-dark' && !isBravePromo && !isActive && isReady && (
        <NoFeesBanner
          theatricalSlug={theatricalRelease?.theatricalSlug}
          className={promoPlacementExperiment ? 'my-4 overflow-hidden rounded-xl !pr-0' : '!hidden md:!flex'}
          code="NOFEES"
        />
      )}
      {isBravePromo && currentUrlPromo && PROMO_BANNERS[currentUrlPromo as keyof typeof PROMO_BANNERS] && (
        <>{PROMO_BANNERS[currentUrlPromo as keyof typeof PROMO_BANNERS]}</>
      )}
    </>
  )
}

export default TicketBannerSection
