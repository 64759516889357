import { useMemo, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { isAfter, startOfDay } from 'date-fns'
import { isEqual } from 'lodash'
import {
  GetGiveawayScheduleQuery,
  GetGiveawayScheduleQueryVariables,
  SweepstakesEntriesTypes,
  SweepstakesScheduleItemObject,
} from '@/types/codegen-federation'

const GET_GIVEAWAY_SCHEDULE = gql`
  query getGiveawaySchedule($input: TicketSalesPromosInput!) {
    ticketSalesPromos(input: $input) {
      sweepstakesSchedule {
        isActive
        name
        endDate
        items {
          ticketType
          multiplier
          entriesPerTicket
          startDate
          endDate
        }
      }
    }
  }
`

export const useGiveawaySchedule = (theatricalSlug: string | undefined) => {
  const [isReady, setIsReady] = useState(false)
  const { data, refetch } = useQuery<GetGiveawayScheduleQuery, GetGiveawayScheduleQueryVariables>(
    GET_GIVEAWAY_SCHEDULE,
    {
      variables: { input: { projectSlug: theatricalSlug } },
      onCompleted: () => setIsReady(true),
    },
  )

  return useMemo(() => {
    const giveawaySchedule = data?.ticketSalesPromos?.sweepstakesSchedule?.[0]
    const schedules = giveawaySchedule?.items

    return {
      isReady,
      isActive: giveawaySchedule?.endDate ? isGiveawayActive(giveawaySchedule.endDate) : false,
      presale: findSchedule(schedules, 'PRESALE'),
      normal: findSchedule(schedules, 'NORMAL'),
      guildSubscription: findSchedule(schedules, 'GUILD_SIGNUP_MONTHLY'),
      guildTicketRedemption: findSchedule(schedules, 'GUILD_TICKET'),
      refetch,
    }
  }, [data?.ticketSalesPromos?.sweepstakesSchedule, isReady, refetch])
}

const findSchedule = (schedules: SweepstakesScheduleItemObject[] | null | undefined, type: SweepstakesEntriesTypes) => {
  if (!schedules) return null
  return schedules.find((schedule) => schedule.ticketType === type)
}

export const isGiveawayActive = (endDate: Date) => {
  const parsedEndDate = new Date(endDate)
  const startOfEndDate = startOfDay(parsedEndDate)
  const startOfToday = startOfDay(new Date())
  return isEqual(startOfEndDate, startOfToday) || isAfter(startOfEndDate, startOfToday)
}
