import React, { FC, useCallback } from 'react'
import { LinkButton } from '@/atoms/Button'
import { Image } from '@/atoms/Image'
import { Text, TitleMD } from '@/atoms/Text'
import { slugs } from '@/constants/slugs'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

interface Props {
  theatricalSlug: string
}
export const EmptyStateGiveaway: FC<Props> = ({ theatricalSlug }) => {
  const { t } = useTranslate('tickets')
  const track = useSafeTrack()

  const handleButtonClick = useCallback(() => {
    track('Pre-Order Tickets Button Clicked')
  }, [track])

  if (theatricalSlug === slugs.braveTheDark || theatricalSlug === slugs.ruleBreakers) {
    return (
      <div className="mx-auto mb-6 mt-4 grid max-w-[480px] place-items-center gap-6 text-center">
        <Image
          priority
          alt=""
          src="/v1712858189/share-seats-illustration-colored-02_2x.webp"
          width={180}
          height={136}
        />

        <div className="flex flex-col gap-2">
          <TitleMD weight="bold">{t('showtimesComingSoonGiveawayEmpty', 'Showtimes Coming Soon')}</TitleMD>

          <Text className="photon-paragraph-sm md:photon-paragraph-md" color="core-gray-700">
            {t(
              'showtimesComingSoonDescriptionGiveawayEmptyV2',
              "We're adding new theaters every week. While you wait, pre-order tickets today for your chance to win the $200k Dream Vacation Giveaway!",
            )}
          </Text>
        </div>

        <LinkButton
          onClick={handleButtonClick}
          href={`/pre-sales/${theatricalSlug}/giveaway`}
          className="w-fit"
          variant="oxide-primary"
        >
          {t('preOrderTicketsGiveawayEmpty', 'Pre-Order Tickets')}
        </LinkButton>
      </div>
    )
  }

  return null
}
