import React, { useEffect } from 'react'
import { Button, LinkButton } from '@/atoms/Button'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { Image } from '@/atoms/Image'
import { Modal } from '@/molecules/Modal'
import { getBooleanFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import {
  HomesteadButtonText,
  HomesteadEyebrowMD,
  HomesteadTitleSM,
  HomesteadTitleXL,
} from '@/views/PreSales/PreSalesGiveawayView/components/Typography'
import { useGiveawaySchedule } from '@/views/PreSales/PreSalesGiveawayView/hooks/useGiveawaySchedule'
import { CountdownTimer } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GiveawayModal/CountdownTimer'
import { useMultiplier } from '../../banners/GiveawayBanners/utils'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const BraveTheDarkGiveawayBanner: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslate('tickets')

  const { refetch } = useGiveawaySchedule('brave-the-dark')
  const multiplier = useMultiplier()

  useEffect(() => {
    if (isOpen) {
      const hasSeenModal = getBooleanFromLocalStorage('btd-modal-giveaway')
      if (!hasSeenModal) {
        writeToLocalStorage('btd-modal-giveaway', true)
      }
    }
  }, [isOpen])

  if (!multiplier) return null

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      panelClasses="!max-w-[480px] !w-[calc(100%-1.75rem)] lg:!w-full !p-0"
      childWrapperClassName="!mt-0"
      className="!z-[60]"
    >
      <div className="relative flex flex-col bg-[#0C1C29]">
        <div className="relative h-[240px]">
          <Image
            width={480}
            height={360}
            src={`/v1737739214/brave-the-dark/giveaway-tabeletpromo-banner-img`}
            alt={t('BtdGiveawayModalHeader2', 'Brave the Dark Giveaway modal Header')}
            className="h-full object-cover object-[50%_90%]"
          />
        </div>
        <button
          onClick={onClose}
          className="absolute right-6 top-5 z-10 flex size-8 cursor-pointer items-center justify-center rounded-full bg-gray-200/50 transition-bg hover:bg-gray-200/90"
        >
          <CloseIcon size={16} color="core-gray-950" />
        </button>

        <div className="relative z-10 px-6 pb-6 pt-7 text-left">
          <div className="inline-block translate-y-[-60%] bg-[#FFA337] px-3 py-2">
            <HomesteadEyebrowMD className="!font-apotek !text-[16px] !font-semibold !leading-[16px] !tracking-[0.75px]">
              {t('thisWeekOnly', 'This week only')}
            </HomesteadEyebrowMD>
            <HomesteadTitleSM className="-mb-[2px] -mt-1 !font-apotek !text-[21px] !font-semibold !leading-[28px]">
              <Translate
                t={t}
                i18nKey="getMultiplerYourEntriesModal"
                defaults="Get <bold>{{multiplier}}x</bold> Your Entries"
                components={{ bold: <span className="font-bold" /> }}
                values={{ multiplier }}
              />
            </HomesteadTitleSM>
            <CountdownTimer onCountdownEnd={refetch} />
          </div>
          <HomesteadTitleXL className="!-mt-7 !text-pretty !font-apotek !text-[1.75rem] !font-bold !leading-8 !tracking-[1.5px] text-white lg:!-mt-6 lg:!text-[2rem]">
            {t(
              'watcBTDInTheatersAndYouCouldWinvaction',
              'Watch Brave the Dark in Theaters and You Could Win The $200k Dream Vacation Giveaway!',
            )}
          </HomesteadTitleXL>

          <Button
            variant="plain"
            onClick={onClose}
            className="mt-7 rounded-lg bg-[#AE3232] !px-[1.25rem] !py-[.688em] !font-apotek text-white transition-all duration-300 ease-in-out lg:!px-[1.5rem] lg:!py-[.875em]"
          >
            <HomesteadButtonText>{t('getShowtimes', 'Get Showtimes')}</HomesteadButtonText>
          </Button>
          <LinkButton
            variant="plain"
            href={'https://www.angel.com/movies/brave-the-dark'}
            target="_blank"
            onClick={onClose}
            className="ml-3 mt-7 inline-block rounded-lg border-2 border-[#C9D1D7] bg-none !px-[1.25rem] !py-[.563em] !font-apotek !text-[#C9D1D7] transition-all duration-300 ease-in-out lg:!px-[1.5rem] lg:!py-[.75em]"
          >
            <HomesteadButtonText className="!font-apotek !text-[14px] !tracking-[0.75px] lg:!text-[16px]">
              {t('learnMoreModal', 'Learn More')}
            </HomesteadButtonText>
          </LinkButton>
        </div>
      </div>
    </Modal>
  )
}
