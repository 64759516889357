import React, { useMemo, useRef } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { isMobile } from 'react-device-detect'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { UploadIcon } from '@/atoms/Icons/UploadIcon'
import { Text, TitleXS } from '@/atoms/Text'
import { useThemeContext } from '@/contexts/ThemeContext'
import { TicketsMainFooter } from '@/organisms/Footer/TicketsMainFooter'
import { TheatricalPromoType } from '@/services/Theatrical/contentfulQueries'
import { GroupTicketOption } from '@/types/codegen-contentful'
import { ReservationObject, TheatricalReleaseObject, TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'
import { AddressSearch } from '@/views/TicketCheckoutViews/ShowtimesView/components/AddressSearch'
import { Calendar } from '@/views/TicketCheckoutViews/ShowtimesView/components/Calendar'
import { ComingSoon } from '@/views/TicketCheckoutViews/ShowtimesView/components/ComingSoon'
import FandangoRedirect from '@/views/TicketCheckoutViews/ShowtimesView/components/FandangoRedirect'
import TicketBannerSection from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketBannerSection'
import { useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import { TicketsPayItForwardAd } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPayItForwardAd'
import Venues from '@/views/TicketCheckoutViews/ShowtimesView/components/Venues'
import TicketPromoModals from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/TicketPromoModals'
import { useFindQueryVenue } from '@/views/TicketCheckoutViews/ShowtimesView/hooks/useFindQueryVenue'
import { useVenueSearch } from '@/views/TicketCheckoutViews/ShowtimesView/hooks/useVenueSearch'

interface TicketSearchProps {
  theatricalSlug: string
  theatricalRelease: TheatricalReleaseObject
  groupTicketOptions: GroupTicketOption[]
  scrollToTop: () => void
  calendarRef: React.RefObject<HTMLDivElement>
  reservation?: ReservationObject
  isEditReservationFlow?: boolean
  promo?: TheatricalPromoType
  projectGuildScore: {
    guildScore: number | undefined
  }
}

export const ShowtimesLayout = ({
  theatricalRelease,
  groupTicketOptions,
  calendarRef,
  theatricalSlug,
  isEditReservationFlow = false,
  reservation,
  scrollToTop,
  promo,
}: TicketSearchProps) => {
  const { query: urlQuery } = useRouter()
  const { isDarkMode } = useThemeContext()
  const { t } = useTranslate('tickets')
  const searchScrollContainer = useRef<HTMLDivElement>(null)
  const { openSharingModal, handleMobileShare, promoPlacementExperiment } = useTicketsContext()

  const releaseDate = useMemo(() => {
    try {
      return theatricalRelease?.region?.releaseDate ? new Date(theatricalRelease?.region?.releaseDate) : new Date()
    } catch (err) {
      return new Date()
    }
  }, [theatricalRelease?.region?.releaseDate])

  const {
    searchDate,
    handleDateChange,
    handleAddressChange,
    earliestDate,
    venues,
    loading,
    hasSearchedOnce,
    defaultAddressString,
    error,
  } = useVenueSearch({
    releaseDate: releaseDate,
    theatricalSlug,
    countryCode: theatricalRelease?.region?.countryCode ?? 'us',
  })
  const { venue } = useFindQueryVenue({ venues: venues as TheatricalShowtimeVenue[] })

  const theatricalReleaseName = theatricalRelease.title ?? ''
  const shouldShowTicketsComingSoon = theatricalRelease?.region?.ticketsStatus === 'COMING_SOON'
  return (
    <>
      <main
        ref={searchScrollContainer}
        className={classNames(
          'w-full gap-y-2 px-4 lg:overflow-y-scroll',
          isDarkMode ? 'bg-core-gray-950' : 'bg-core-gray-100',
        )}
      >
        <h1 className="sr-only">
          {t('projectTicketsAndShowtimes', '{{projectName}} Tickets & Showtimes', {
            projectName: theatricalRelease?.title,
          })}
        </h1>

        <div className="-mx-4">
          {!promoPlacementExperiment && (
            <TicketBannerSection
              reservation={reservation}
              isEditReservationFlow={isEditReservationFlow}
              promo={promo}
              theatricalRelease={theatricalRelease}
              calendarRef={calendarRef}
              promoPlacementExperiment={promoPlacementExperiment}
            />
          )}
        </div>
        <div className="flex min-h-[50vh] flex-col">
          <div className="pt-5">
            <FlexColumn className="gap-2">
              <div className="flex flex-row flex-wrap items-end justify-between">
                <label htmlFor="search">
                  <TitleXS weight="bold" className="text-core-gray-950">
                    {t('enterCityStateZipV3', '   Search Location')}
                  </TitleXS>
                </label>
                <button className="flex flex-row gap-1" onClick={isMobile ? handleMobileShare : openSharingModal}>
                  <TitleXS weight="semibold" className="my-auto cursor-pointer text-sm text-core-gray-950">
                    {t('share', 'Share')}
                  </TitleXS>
                  <UploadIcon color="core-gray-950" size={18} />
                </button>
              </div>
              {urlQuery?.promo === 'stop-hate' && (
                <FlexRow className="w-[350px] gap-1 py-[5px]">
                  <TicketIcon color="error-700" size={18} />
                  <Text className="photon-title-xxs !text-error-700">
                    {t('buyTicketHelpFightAntisemitism', 'Buy a ticket to help fight Antisemitism!')}
                  </Text>
                </FlexRow>
              )}
              <AddressSearch
                countryCode={theatricalRelease?.region?.countryCode ?? 'us'}
                onChange={handleAddressChange}
                defaultAddressString={defaultAddressString}
              />
            </FlexColumn>

            <Calendar
              releaseDate={theatricalRelease?.region?.releaseDate}
              className="-mx-4 mt-10 min-h-[120px]"
              disabled={false}
              onChange={handleDateChange}
              selectedDate={searchDate}
              startDate={earliestDate}
              scrollToTop={scrollToTop}
              calendarRef={calendarRef}
              theatricalSlug={theatricalSlug}
              theatricalRelease={theatricalRelease}
            />
            {promoPlacementExperiment && (
              <TicketBannerSection
                reservation={reservation}
                isEditReservationFlow={isEditReservationFlow}
                promo={promo}
                theatricalRelease={theatricalRelease}
                calendarRef={calendarRef}
                promoPlacementExperiment={promoPlacementExperiment}
              />
            )}
          </div>
          {shouldShowTicketsComingSoon ? (
            <ComingSoon slug={theatricalRelease?.theatricalSlug} />
          ) : (
            <Venues
              venues={venues as TheatricalShowtimeVenue[]}
              theatricalRelease={theatricalRelease}
              isLoading={loading || !hasSearchedOnce}
              groupTicketOptions={groupTicketOptions}
              reservation={reservation}
            />
          )}
        </div>
        <TicketsMainFooter
          className="-mx-4 mt-6"
          slug={theatricalRelease.projectSlug}
          projectName={theatricalReleaseName}
        />
      </main>
      <TicketsPayItForwardAd
        theatricalSlug={theatricalSlug}
        theatricalRelease={theatricalRelease}
        className="justify-center text-center md:hidden"
      />
      <FandangoRedirect
        fandangoUrl={theatricalRelease?.region?.ticketsEmergencyRedirectUrl ?? ''}
        shouldRedirectToFandango={!!error && !shouldShowTicketsComingSoon}
      />
      <TicketPromoModals
        venue={venue}
        promo={promo}
        isEditReservationFlow={isEditReservationFlow}
        theatricalRelease={theatricalRelease}
        reservation={reservation}
      />
    </>
  )
}
