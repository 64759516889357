import { useToast } from '@/molecules/Toast'
import { logger } from '@/utils/logging'
import { useTranslate } from '@/utils/translate/translate-client'

interface Props {
  onSuccess?: () => void
  customToastMessage?: string
  shouldShowToast?: boolean
}

export const useCopyToClipboard = (props: Partial<Props> = {}) => {
  const { showToast } = useToast()
  const { t } = useTranslate('common')
  const { onSuccess, customToastMessage, shouldShowToast = true } = props

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)
      if (onSuccess) onSuccess()
      if (shouldShowToast) showToast(customToastMessage ?? t('copiedToClipboardHook', 'Copied to clipboard!'))
      return true
    } catch (err) {
      logger().error(`Error copying text: `, err)
      return false
    }
  }
  return [copyToClipboard]
}
